<template>
    <div>
        
    </div>
</template>
<script>
import { getLanguage } from '@/untils/js/common.js'
export default {
    data() {
        return {
            
        }
    },
    created() {
        
    },
    mounted() {
        let lang = localStorage.getItem('lang')
        lang = getLanguage()
        if (window.location.href.indexOf('/en') != -1) {
            localStorage.setItem('lang', 'en')
        }
        if(lang == 'zh' && window.location.href.indexOf('/en') == -1) {
            localStorage.setItem('lang', 'zh')
            if (window.location.href.indexOf('/zh') == -1) {
                window.location.href = "https://m.moobyyoho.com/zh"
            }
        } else {
            localStorage.setItem('lang', 'en')
            if (window.location.href.indexOf('/en') == -1) {
                window.location.href = "https://m.moobyyoho.com/en"
            }
        }
    },
    methods: {
        
    }
};
</script>
<style scoped lang="less">
    
</style>